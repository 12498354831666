import React from "react";
import { Box, Tooltip } from "@chakra-ui/core";
export const deployLogo = deploy => {
  if (deploy.includes("heroku.com")) {
    return (<Tooltip bg="rgb(45, 55, 72)" color="rgba(255, 255, 255, 0.92);" hasArrow label={"Deploy via Heroku"} placement="top">
      <Box>
        <svg width="10px" viewBox="0 0 256 400" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet">
          <path d="M28.083 398.289V363.51c0-2.452-1.798-3.51-3.917-3.51-4.248 0-9.554 1.058-14.37 3.181v35.108H0v-64.576h9.795v21.304c4.656-1.712 10.206-3.18 15.758-3.18 8.898 0 12.246 5.469 12.246 12.976v33.476h-9.716zm27.999-21.063c.326 11.674 2.613 13.961 9.794 13.961 5.634 0 12.002-1.879 16.902-3.757l1.632 7.346c-5.226 2.37-11.593 4.655-19.183 4.655-16.33 0-18.862-8.978-18.862-23.268 0-7.835.573-14.939 2.45-21.47 4.898-1.878 11.43-2.857 19.673-2.857 13.393 0 17.473 7.43 17.473 20.41v4.98H56.082zM68.488 360c-2.935 0-7.59.082-11.427.813-.406 1.96-.899 4.655-1.062 9.636h20.41c0-6.778-1.225-10.449-7.921-10.449zm35.837 3.181v35.108h-9.797v-39.515c8.246-4.489 16.981-5.877 22.698-6.285v8.164c-4 .326-9.064.816-12.9 2.528zm38.778 36.25c-14.616 0-21.228-7.183-21.228-23.594 0-17.389 8.735-24 21.228-24 14.612 0 21.226 7.182 21.226 23.592 0 17.39-8.737 24.002-21.226 24.002zm0-39.43c-7.512 0-11.675 4.325-11.675 15.836 0 12.574 3.51 15.35 11.675 15.35 7.51 0 11.674-4.247 11.674-15.758 0-12.574-3.51-15.429-11.674-15.429zm68.49 38.288H200.08c-2.692-7.184-6.45-14.532-12.246-20.9h-5.144v20.9h-9.796v-64.576h9.796v37.062h4.573c4.98-5.144 8.816-11.509 11.511-17.797h11.02c-3.754 7.593-8.57 14.287-13.959 19.757 6.45 8.164 11.511 16.818 15.757 25.554zm18.363 1.142c-8.897 0-12.244-5.468-12.244-12.98v-33.473h9.714v34.697c0 2.452 1.794 3.512 3.917 3.512 4.246 0 10.042-1.06 14.86-3.184v-35.025H256v39.35c-11.593 6.369-20.493 7.103-26.044 7.103zM225.628 317.253H30.258C13.545 317.253 0 303.708 0 286.998V30.256C0 13.546 13.546 0 30.257 0h195.37c16.71 0 30.26 13.546 30.26 30.256v256.742c0 16.71-13.55 30.255-30.26 30.255z" fill="#6762A6" />
          <path d="M160.36 273.6V147.61s8.195-30.15-100.943 12.334c-.2.539-.2-116.504-.2-116.504l35.66-.22v74.991s99.846-39.325 99.846 29.824V273.6h-34.362zm20.32-184.994h-37.824c13.615-16.646 25.94-45.167 25.94-45.167h39.11s-6.696 18.587-27.225 45.167zM59.865 273.382v-71.748l35.878 35.877-35.878 35.871z" fill="#FFF" />
        </svg>
      </Box>
    </Tooltip>);
  }
  if (deploy.includes("digitalocean")) {
    return (<Tooltip bg="rgb(45, 55, 72)" color="rgba(255, 255, 255, 0.92);" hasArrow label={"Deploy via DigitalOcean"} placement="top">
      <Box>
        <svg width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 -3.954 53.927 53.954">
          <g fill="#0080ff" fill-rule="evenodd">
            <path d="M24.915 50v-9.661c10.226 0 18.164-10.141 14.237-20.904a14.438 14.438 0 0 0-8.615-8.616C19.774 6.921 9.633 14.83 9.633 25.056H0C0 8.758 15.763-3.954 32.853 1.384 40.311 3.73 46.271 9.661 48.588 17.12 53.927 34.237 41.243 50 24.915 50" />
            <path d="M15.339 40.367h9.604v-9.604H15.34zm-7.401 7.401h7.4v-7.4h-7.4zm-6.187-7.4h6.187V34.18H1.751z" />
          </g>
        </svg>
      </Box>
    </Tooltip>);
  }
  if (deploy.includes("zeit.co")) {
    return (<Tooltip bg="rgb(45, 55, 72)" color="rgba(255, 255, 255, 0.92);" hasArrow label={"Deploy via Now.sh"} placement="top">
      <Box size={3}>
        <svg viewBox="0 0 114 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="100.929941%" y1="181.283245%" x2="41.7687834%" y2="100%" id="linearGradient-1">
              <stop stop-color="#FFFFFF" offset="0%"></stop>
              <stop stop-color="#000000" offset="100%"></stop>
            </linearGradient>
          </defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Black-Triangle" transform="translate(-293.000000, -150.000000)" fill="url(#linearGradient-1)">
              <polygon id="Logotype---Black" points="350 150 407 250 293 250"></polygon>
            </g>
          </g>
        </svg>
      </Box>
    </Tooltip>);
  }
  if (deploy.includes("render")) {
    return (<Tooltip bg="rgb(45, 55, 72)" color="rgba(255, 255, 255, 0.92);" hasArrow label={"Deploy via Render"} placement="top">
      <Box size={3}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 171 171">
          <g transform="translate(-15853 -8503)" fill="#46E3B7">
            <g>
              <polygon class="st0" points="15966.3,8503 15964.3,8503 15964.3,8505 15964.3,8523.6 15964.3,8525.6 15966.3,8525.6 15984.9,8525.6 15986.9,8525.6 15986.9,8523.6 15986.9,8505 15986.9,8503 15984.9,8503 		"/>
              <path class="st0" d="M15906.5,8503c-7.2,0-14.2,1.4-20.8,4.2c-6.4,2.7-12.1,6.6-17,11.5s-8.8,10.6-11.5,17 c-2.8,6.6-4.2,13.6-4.2,20.8v78.4v2h2h18.6h2v-2v-78.7c0.4-8.1,3.8-15.7,9.5-21.3c5.8-5.7,13.4-9,21.5-9.3h41.2h2v-2V8505v-2h-2 H15906.5z"/>
              <polygon class="st0" points="16003.4,8577.2 16001.4,8577.2 16001.4,8579.2 16001.4,8597.8 16001.4,8599.8 16003.4,8599.8 16022,8599.8 16024,8599.8 16024,8597.8 16024,8579.2 16024,8577.2 16022,8577.2 		"/>
              <polygon class="st0" points="16003.4,8540.1 16001.4,8540.1 16001.4,8542.1 16001.4,8560.7 16001.4,8562.7 16003.4,8562.7 16022,8562.7 16024,8562.7 16024,8560.7 16024,8542.1 16024,8540.1 16022,8540.1 		"/>
              <polygon class="st0" points="16022,8503 16003.4,8503 16001.4,8503 16001.4,8505 16001.4,8523.6 16001.4,8525.6 16003.4,8525.6 16022,8525.6 16024,8525.6 16024,8523.6 16024,8505 16024,8503 		"/>
              <polygon class="st0" points="16003.4,8614.3 16001.4,8614.3 16001.4,8616.3 16001.4,8634.9 16001.4,8636.9 16003.4,8636.9 16022,8636.9 16024,8636.9 16024,8634.9 16024,8616.3 16024,8614.3 16022,8614.3 		"/>
              <polygon class="st0" points="16003.4,8651.4 16001.4,8651.4 16001.4,8653.4 16001.4,8672 16001.4,8674 16003.4,8674 16022,8674 16024,8674 16024,8672 16024,8653.4 16024,8651.4 16022,8651.4 		"/>
              <polygon class="st0" points="15966.3,8651.4 15964.3,8651.4 15964.3,8653.4 15964.3,8672 15964.3,8674 15966.3,8674 15984.9,8674 15986.9,8674 15986.9,8672 15986.9,8653.4 15986.9,8651.4 15984.9,8651.4 		"/>
              <polygon class="st0" points="15929.2,8651.4 15927.2,8651.4 15927.2,8653.4 15927.2,8672 15927.2,8674 15929.2,8674 15947.8,8674 15949.8,8674 15949.8,8672 15949.8,8653.4 15949.8,8651.4 15947.8,8651.4 		"/>
              <polygon class="st0" points="15892.1,8651.4 15890.1,8651.4 15890.1,8653.4 15890.1,8672 15890.1,8674 15892.1,8674 15910.7,8674 15912.7,8674 15912.7,8672 15912.7,8653.4 15912.7,8651.4 15910.7,8651.4 		"/>
              <polygon class="st0" points="15855,8651.4 15853,8651.4 15853,8653.4 15853,8672 15853,8674 15855,8674 15873.6,8674 15875.6,8674 15875.6,8672 15875.6,8653.4 15875.6,8651.4 15873.6,8651.4 		"/>
            </g>
          </g>
        </svg>
      </Box>
    </Tooltip>);
  }
  if (deploy) {
    return (<Tooltip hasArrow label={"Deploy instructions"} placement="top">
      <Box size={4}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15M9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5M9 14L11 16L15 12" stroke="#718096" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </Box>
    </Tooltip>);
  }
};
